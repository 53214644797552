<template>
  <div class="baseInfo-container">
    <div class="title">入驻申请</div>
    <div class="wec">基本信息</div>
    <div class="box">
      <el-form ref="form" :model="postForm" :rules="formRules" label-width="130px">
        <el-form-item class="dis-flex" label="店铺类型">
          <span>{{ shopType[query.radio] }}</span>
        </el-form-item>
        <template>
          <el-form-item class="dis-flex" prop="shop_name" label="店铺名称：">
            <el-input size="mini" v-model="postForm.shop_name" placeholder="请输入店铺名称"></el-input>
          </el-form-item>
          <el-form-item class="dis-flex" v-if="shop_type == 1" prop="web_url" label="店铺网络地址">
            <el-input size="mini" v-model="postForm.web_url" placeholder="请输入店铺网络地址"></el-input>
          </el-form-item>
          <el-form-item class="dis-flex" prop="address" label="店铺地址：">
            <el-input size="mini" v-model="postForm.address" placeholder="请输入店铺地址"></el-input>
          </el-form-item>
          <el-form-item label="主营类型：">
            <el-radio-group v-model="postForm.main_business_type">
              <el-radio :label="1">猫粮</el-radio>
              <el-radio :label="2">犬粮</el-radio>
              <el-radio :label="3">猫粮及犬粮</el-radio>
            </el-radio-group>
          </el-form-item>

          <div class="mt-15">
            店铺经营类型证明：
            <span class="tip">请提供店铺经营类型的照片，例如营业执照、店面照片,店铺的管理后台截图等</span>
          </div>
          <!-- <div class="mt-15" >
            网店管理后台截图：
            <span class="tip">请提供至少1张店铺的管理后台截图，例如：千牛店铺管理后台</span>
          </div> -->
          <div class="dis-flex mt-15">
            <UploadImage :msg="''" :num="1" @setUrl="setUrl"></UploadImage>
            <UploadImage :msg="''" :num="2" @setUrl="setUrl"></UploadImage>
            <UploadImage :msg="''" :num="3" @setUrl="setUrl"></UploadImage>


          </div>
        </template>
        <el-form-item class="dis-flex" label="平台店铺ID：">
          <el-input size="mini" v-model="postForm.platform_id" placeholder="请输入平台店铺ID"></el-input>
        </el-form-item>
        <el-form-item class="dis-flex" prop="phone" label="负责人电话号：">
          <el-input size="mini" v-model="postForm.phone" placeholder="请输入负责人联系电话"></el-input>
          <span class="tip">请务必如实填写</span>
        </el-form-item>
        <el-form-item class="dis-flex" prop="leader_wechat" label="负责人微信号：">
          <el-input size="mini" v-model="postForm.leader_wechat" placeholder="请输入负责人微信号"></el-input>
          <span class="tip">请务必如实填写</span>
        </el-form-item>

        <div class="btns dis-flex">
          <el-button type="primary" @click="$router.go(-1)" size="small">上一步</el-button>
          <el-button type="primary" @click="dataSubmit()" size="small">提交</el-button>
        </div>
      </el-form>
    </div>

  </div>

</template>

<script>
import { BASE_URL2 as baseUrl } from '../request/request.config'
import UploadImage from '../components/UploadImage.vue';
export default {
  name: "info",
  components: {
    UploadImage
  },
  data() {
    return {
      shop_type: "",
      query: {},
      baseUrl: baseUrl + '/merapi/upload/img_apply',
      shopType: {
        1: "线上网店",
        2: "线下宠物店",
        3: "猫舍",
        4: "犬舍",
        5: "宠物医院",
        6: "宠物微商"
      },
      postForm: {
        shop_name: "",
        web_url: "",
        main_business_type: 1,
        phone: "",
        leader_wechat: "",
        platform_id: "",
        address: "",
      },
      formRules: { // 表单校验
        shop_name: [
          { required: true, message: '网店名称必填' }
        ],
        web_url: [
          { required: true, message: '网店地址必填' }
        ],
        off_shop_name: [
          { required: true, message: '线下店名称必填' }
        ],
        address: [
          { required: true, message: '线下店地址必填' }
        ],
        phone: [
          { required: true, message: '负责人手机号必填' }
        ],
        leader_wechat: [
          { required: true, message: '负责人微信号必填' }
        ],
      },
      input: "",
      imageUrl1: "",
      imageUrl2: "",
      imageUrl3: "",
      imageUrl4: "",
      imageUrl5: "",
      imageUrl6: "",
      shop_type_name: "",
    };
  },
  created() {
    this.query = this.$route.query;
    this.shop_type = this.$route.query.radio
  },
  methods: {
    setUrl(url, num) {
      let imgUrl = "imageUrl" + num;
      this[imgUrl] = url
    },



    dataSubmit() {
      this.$refs.form.validate(valid => {

        if (valid) {
          let data = this.postForm
          data.type_id = this.shop_type;
          let reg_tel = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
          if (!reg_tel.test(data.phone)) {
            return this.$message.error('请输入正确的手机号')
          }
          data.img_url = [this.imageUrl1, this.imageUrl2, this.imageUrl3].filter(item => item != "").join(",")
          if(data.img_url == '') {
            return this.$message.error('请上传入驻凭证')
          }
          data.register_source = 1;
          this.$http2.post('/merapi/shop/apply', data).then(res => {
            if (res.data.code == 200) {
              this.$message.success(res.data.msg)
              this.$router.push("/login")
            } else {
              return this.$message.error(res.data.msg)
            }
          }).catch(err => {
            return this.$message.error(err)
          })
        }
      })
    }
  },
};
</script>

<style lang="less" scoped>
.baseInfo-container {
  font-size: 14px;
  width: 100%;
  height: 100%;
  display: flex;
  color: #606266;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .el-form {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    .el-form-item {
      margin-bottom: 0;

      /deep/.el-form-item__label {
        text-align: left;
        padding-right: 0;
        font-size: 13px;
      }

      /deep/.el-form-item__content {
        margin-left: 0 !important;
      }
    }
  }

  .mt-15 {
    margin-top: 12px;
  }

  .title {
    margin-bottom: 10px;
    width: 200px;
    text-align: center;
    color: #fff;
    font-weight: 700;
    padding: 5px 20px;
    background-color: rgb(245, 154, 35);
  }

  .btns {
    display: flex;
    justify-content: space-between;
  }

  .wec {
    text-align: center;
    color: rgb(217, 0, 27);
    margin-bottom: 10px;
    font-weight: 700;
    font-size: 16px;
  }

  .box {
    border: 1px dashed #222;
    min-height: 580px;

    padding: 20px;
    box-sizing: border-box;

    .poa-bot {
      margin-top: 5px;
    }

    .tip {
      font-size: 12px;
      margin-left: 5px;
      color: rgb(217, 0, 27);
    }

    .dis-flex {
      display: flex;
      align-items: center;

      //   margin-top: 12px;
      .el-input {
        width: 350px;
      }
    }
  }
}
</style>
