<template>
  <div class="upload-box">
    <el-upload
      class="avatar-uploader"
      :action="baseUrl"
      :show-file-list="false"
      name="upload_file"
      :on-error="handleAvatarError"
      :on-success="handleAvatarSuccess"
      :before-upload="beforeAvatarUpload"
    >
      <img v-if="imageUrl" :src="imageUrl" class="avatar" />
      <i v-else class="el-icon-plus avatar-uploader-icon"></i>
    </el-upload>
    <div v-if="msg" class="poa-bot">{{ msg }}</div>
  </div>
</template>
<script>
import { BASE_URL2 } from "../request/request.config";
export default {
  name: "navMenu",
  props: {
    msg: {},
    num: {},
  },
  data() {
    return {
      imageUrl: "",
      baseUrl: BASE_URL2 + "/merapi/upload/img_apply",
    };
  },

  methods: {
    handleAvatarError(err, file, fileList) {
      console.log(err);
    },
    handleAvatarSuccess(res, file) {
      if (res.code === 1) {
        return this.$message.error(res.msg);
      }
      this.imageUrl = res.data.path;
      this.$emit("setUrl", this.imageUrl, this.num);
    },
    beforeAvatarUpload(file) {
      const isFormat = file.type == "image/jpeg" || file.type == "image/png";
      const isLt2M = file.size / 1024 / 1024 < 2;
      console.log(file);
      if (!isFormat) {
        this.$message.error("上传头像图片只能是 JPG或png 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      return isFormat && isLt2M;
    },
  },
  mounted() {},
  computed: {},
};
</script>
<style scoped lang="less">
.avatar-uploader {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 70px;
  height: 70px;
  line-height: 70px;
  text-align: center;
}
.avatar {
  width: 70px;
  height: 70px;
  display: block;
}
.upload-box {
  display: flex;
  flex-direction: column;
  width: 200px;
  justify-content: center;
  align-items: center;
}
</style>
